/** @jsx jsx */
import { Container, Flex, Grid, Heading, Text } from '@theme-ui/components'
import { useState } from 'react'
import { jsx } from 'theme-ui'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Helmet } from 'react-helmet'
import { withPrefix } from 'gatsby'

const ProductRegistrationPage = ({ page }) => {
  const models = [
    'Super73 V1 Original (2016)',
    'Super73-V2 (2018)',
    'Super73-OG',
    'Super73-S1 "Rose Ave."',
    'Super73-S',
    'Super73-S1',
    'Super73-SG',
    'Super73-SG1',
    'Super73-R',
    'Super73-R BROOKLYN',
    'Super73-RX',
    'Super73-RX MOJAVE',
    'Super73-S2',
    'Super73-Z MIAMI',
    'Super73-Z1',
    'Super73-ZX',
    'Other'
  ]

  const assemblyOptions = [
    'Yes (if so, please be ready to provide proof)',
    'No, I assembled it myself (not an E-bike specialist)',
    'Picked it up from SUPER73 HQ or authorized dealer',
    'Other'
  ]

  const purchaseLocation = [
    'SUPER73 HQ',
    'SUPER73 website',
    'A dealer in the USA',
    'A dealer in Europe',
    'A dealer in Australia',
    'Other'
  ]

  const bikeUsage = [
    'Commuting for school/getting around campus',
    'Commuting to work',
    'Use on the job',
    'General transportation around a big city (for everything: work, errands, going out, etc)',
    'Weekend leisure/just to have fun',
    'Leisure: Off-roading',
    'Farms/Hunting/Fishing/Agricultural/Forest grounds',
    'Other'
  ]

  const [otherModel, setOtherModel] = useState(false)
  const [otherAssembled, setOtherAssembled] = useState(false)
  const [otherLocation, setOtherLocation] = useState(false)

  return (
    <Container variant="fullWidth">
      <Helmet>
        <script src={withPrefix('form-script.js')} type="text/javascript" />
      </Helmet>
      <Flex
        sx={{
          width: '100%',
          backgroundColor: page.hero.image ? '#212121' : 'transparent',
          textAlign: 'center',
          color: page.hero.image ? 'white' : 'black',
          flexDirection: ['column', 'row', null]
        }}
      >
        {page.hero.image && (
          <GatsbyImage
            image={page.hero.image.gatsbyImageData}
            alt={page.hero.image.description ?? page.hero.image.title}
            sx={{ width: ['100%', '50%', '65%'], maxWidth: '1300px' }}
          />
        )}
        <Flex
          sx={{
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0 auto',
            py: ['3rem', 0, null]
          }}
        >
          <Heading
            sx={{
              fontSize: [null, '3.2rem', '4.2rem'],
              fontWeight: 'body',
              mb: '1rem',
              px: '1rem'
            }}
          >
            {page.hero.heading?.heading}
          </Heading>
          <Text
            sx={{
              fontSize: '1.6rem',
              fontWeight: 'light',
              px: '12px',
              maxWidth: '750px'
            }}
          >
            {page.hero.subheading?.subheading}
          </Text>
        </Flex>
      </Flex>
      <form
        acceptCharset="UTF-8"
        encType="multipart/form-data"
        id="ajax-product-registration"
        method="post"
        name="ajax-product-registration"
        sx={{
          maxWidth: '760px',
          width: ['85%', '100%', null],
          margin: '0 auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          mt: page.hero.image ? '5rem' : '3rem',
          mb: '5rem',
          fieldset: {
            width: '100%',
            border: 'none'
          },
          'fieldset > div': {
            px: '2rem',
            pt: '3rem',
            pb: '5rem',
            mb: '1rem',
            width: '100%',
            borderRadius: '15px',
            borderColor: 'rgba(0,0,0,0.2)',
            borderStyle: 'solid',
            borderWidth: '1px',
            display: 'grid',
            gap: '3rem',
            gridTemplateColumns: ['1fr', '1fr 1fr'],
            '.fullWidth': {
              gridColumn: ['1/2', '1/3', null]
            }
          },
          legend: {
            fontWeight: 'bold',
            fontSize: '2.4rem',
            px: '10px',
            ml: '1rem'
          },
          strong: {
            fontWeight: 'medium',
            fontSize: '1.5rem',
            mb: '15px',
            lineHeight: '100%',
            display: 'block'
          },
          p: {
            m: 0,
            display: 'flex',
            flexDirection: 'column',
            minWidth: 0,
            ':focus-within': {
              label: {
                transform: 'translateY(-5px)'
              }
            }
          },
          h3: {
            my: '-10px'
          },
          label: {
            fontSize: '1.4rem',
            fontWeight: 'light',
            transition: '0.25s ease',
            mb: '2px'
          },
          'input, textarea': {
            p: '10px 15px',
            fontSize: '1.6rem',
            fontWeight: 'light',
            fontFamily: 'body',
            letterSpacing: '0.1rem',
            borderRadius: '5px',
            border: '1px solid',
            borderColor: 'rgba(0,0,0,0.2)',
            background: 'none',
            transition: 'border-color 0.25s ease, box-shadow 0.25s ease',
            resize: 'vertical',
            ':focus': {
              outline: 'none',
              borderColor: 'border',
              boxShadow: '0 0 0 3px rgba(0,0,0,0.1)'
            }
          },
          'input[type="radio"], input[type="checkbox"]': {
            ':focus': {
              boxShadow: 'none'
            },
            mr: ['5px', '10px', null]
          },
          'input[type="submit"]': {
            backgroundColor: 'black',
            textTransform: 'uppercase',
            color: 'white',
            cursor: 'pointer',
            transition: 'background-color 0.25s ease',
            ':hover': {
              backgroundColor: 'rgba(0,0,0,0.9)'
            },
            ':active': {
              backgroundColor: 'black70'
            }
          },
          '.progress-update': {
            display: 'none'
          },
          small: {
            fontSize: '1.1rem',
            mt: '5px',
            ml: '3px',
            opacity: 0.7
          }
        }}
      >
        <input name="form_type" type="hidden" value="product" />
        <fieldset>
          <div>
            <h3 className="fullWidth">Customer Details</h3>
            <p>
              <label htmlFor="registrationFirstName">First Name</label>
              <input
                type="text"
                name="firstname"
                id="registrationFirstName"
                placeholder="First Name"
                required
              />
            </p>
            <p>
              <label htmlFor="registrationLastName">Last Name</label>
              <input
                type="text"
                name="lastname"
                id="registrationLastName"
                placeholder="Last Name"
                required
              />
            </p>
            <p>
              <label htmlFor="registrationEmail">Email Address</label>
              <input
                type="email"
                name="email"
                id="registrationEmail"
                placeholder="Email Address"
                required
              />
            </p>
            <p>
              <label htmlFor="registrationMobile">Mobile</label>
              <input
                type="tel"
                pattern="[0-9\-]*"
                name="mobile"
                placeholder="Mobile"
                id="registrationMobile"
              />
            </p>
            <p>
              <label htmlFor="registrationDob">Date of Birth</label>
              <input
                type="date"
                name="dob"
                id="registrationDob"
                placeholder="Date of Birth"
              />
            </p>
            <p>
              <label htmlFor="registrationGender">Gender</label>
              <input
                type="text"
                name="gender"
                id="registrationGender"
                placeholder="Gender"
              />
            </p>
          </div>
        </fieldset>
        <fieldset>
          <div>
            <h3>Product Details</h3>
            <div className="fullWidth">
              <div>
                <strong>Model Name</strong>
              </div>
              <Grid columns={[1, 3]}>
                {models.map((model, i) => (
                  <Flex sx={{ alignItems: 'center' }} key={`modelName${i + 1}`}>
                    <input
                      type="radio"
                      name="model"
                      id={`modelName${i + 1}`}
                      value={model}
                      onChange={e =>
                        e.target.value === 'Other' && e.target.checked
                          ? setOtherModel(true)
                          : setOtherModel(false)
                      }
                    />
                    <label htmlFor={`modelName${i + 1}`}>{model}</label>
                  </Flex>
                ))}
              </Grid>
              <Flex
                sx={{
                  alignItems: 'flex-start',
                  flexDirection: 'column',
                  width: '100%',
                  mt: 3,
                  display: otherModel ? 'flex' : 'none',
                  ':focus-within': {
                    '.otherLabel': {
                      transform: 'translateY(-5px)'
                    }
                  }
                }}
              >
                <label htmlFor="otherModelName" className="otherLabel">
                  Other Model Name
                </label>
                <input
                  type="text"
                  name="model-other"
                  id="otherModelName"
                  placeholder="Other Model Name"
                  sx={{ width: '100%' }}
                />
              </Flex>
            </div>
            <div>
              <div>
                <strong>
                  Did you have your Super73 assembled by a specialist?
                </strong>
              </div>
              {assemblyOptions.map((option, i) => (
                <Flex
                  sx={{ alignItems: 'center' }}
                  key={`assembledRadio${i + 1}`}
                >
                  <input
                    type="radio"
                    name="assembledby"
                    id={`assembledRadio${i + 1}`}
                    value={option}
                    onChange={e =>
                      e.target.value === 'Other' && e.target.checked
                        ? setOtherAssembled(true)
                        : setOtherAssembled(false)
                    }
                  />
                  <label htmlFor={`assembledRadio${i + 1}`}>{option}</label>
                </Flex>
              ))}
              <Flex
                sx={{
                  alignItems: 'flex-start',
                  flexDirection: 'column',
                  width: '100%',
                  mt: 3,
                  display: otherAssembled ? 'flex' : 'none',
                  ':focus-within': {
                    '.otherLabel': {
                      transform: 'translateY(-5px)'
                    }
                  }
                }}
              >
                <label htmlFor="otherAssembled" className="otherLabel">
                  Assembled by
                </label>
                <input
                  type="text"
                  name="assembledby-other"
                  id="otherAssembled"
                  placeholder="Assembled By"
                  sx={{ width: '100%' }}
                />
              </Flex>
            </div>
            <div>
              <div>
                <strong>Location of Purchase (dealer, website, etc.)</strong>
              </div>
              {purchaseLocation.map((location, i) => (
                <Flex
                  sx={{ alignItems: 'center' }}
                  key={`purchaseLocation${i + 1}`}
                >
                  <input
                    type="radio"
                    name="purchase-location"
                    id={`purchaseLocation${i + 1}`}
                    value={location}
                    onChange={e =>
                      e.target.value === 'Other' && e.target.checked
                        ? setOtherLocation(true)
                        : setOtherLocation(false)
                    }
                  />
                  <label htmlFor={`purchaseLocation${i + 1}`}>{location}</label>
                </Flex>
              ))}
              <Flex
                sx={{
                  alignItems: 'flex-start',
                  flexDirection: 'column',
                  width: '100%',
                  mt: 3,
                  display: otherLocation ? 'flex' : 'none',
                  ':focus-within': {
                    '.otherLabel': {
                      transform: 'translateY(-5px)'
                    }
                  }
                }}
              >
                <label htmlFor="otherPurchaseLocation" className="otherLabel">
                  Purchase Location
                </label>
                <input
                  type="text"
                  name="other-puchase-location"
                  id="otherPurchaseLocation"
                  placeholder="Other Model Name"
                  sx={{ width: '100%' }}
                />
              </Flex>
            </div>
            <p>
              <label htmlFor="dateofpurchase">Date of Purchase</label>
              <input
                type="date"
                id="dateofpurchase"
                name="date-purchase"
                placeholder="Date of Purchase"
              />
            </p>
            <p>
              <label htmlFor="dateofdelivery">Date of Delivery</label>
              <input
                type="date"
                id="dateofdelivery"
                name="date-delivery"
                placeholder="Date of Delivery"
              />
            </p>
            <p>
              <label htmlFor="productSerial">Product Serial Number</label>
              <input
                id="productSerial"
                name="product-serial"
                placeholder="Product Serial Number"
                type="text"
              />
              <small>
                Engraved on the bottom bracket, between the pedal cranks
              </small>
            </p>
            <p>
              <label htmlFor="orderNumber">Order Number</label>
              <input
                id="orderNumber"
                name="order-serial"
                placeholder="Enter Order Number"
                type="text"
              />
            </p>
            <p>
              <label htmlFor="bikeCity">City (where bike is used)</label>
              <input
                id="bikeCity"
                name="bike-city"
                placeholder="City"
                type="text"
              />
            </p>
            <p>
              <label htmlFor="contactZip">Zip Code (where bike is used)</label>
              <input
                id="contactZip"
                name="bike-zip"
                placeholder="Zip Code"
                type="text"
              />
            </p>
            <p className="fullWidth">
              <label htmlFor="contactCountry">
                Country (where bike is used)
              </label>
              <input
                id="contactCountry"
                name="bike-country"
                placeholder="Country"
                type="text"
              />
            </p>
            <div className="fullWidth">
              <div>
                <strong>What do you primarily use your Super73 for?</strong>
              </div>
              {bikeUsage.map((use, i) => (
                <Flex sx={{ alignItems: 'center' }} key={`primaryUse${i + 1}`}>
                  <input
                    type="checkbox"
                    name="primary_use[]"
                    id={`primaryUse${i + 1}`}
                    value={use}
                  />
                  <label htmlFor={`primaryUse${i + 1}`}>{use}</label>
                </Flex>
              ))}
            </div>
            <p
              className="fullWidth"
              sx={{
                ':focus-within': { label: { transform: 'none !important' } }
              }}
            >
              <label htmlFor="productImage">
                Please upload photos of the product (Optional)
              </label>
              <input
                id="productImage"
                multiple
                name="product_image[]"
                type="file"
                placeholder="Please select JPG, PNG, TIFF, GIF, MOV, MP4, ZIP Files only"
                sx={{ marginBottom: 0 }}
              />
              <small sx={{ marginBottom: '0.75em' }}>
                (Please select JPG, PNG, TIFF, GIF, MOV, MP4, ZIP Files only)
              </small>
            </p>
            <div className="fullWidth">
              <div>
                <strong>
                  I certify that I have read my Super73 Owner's Manual entirely
                  and am familiar with its contents and safety recommendations.
                </strong>
              </div>
              <Flex sx={{ alignItems: 'center' }}>
                <input
                  id="readTerms"
                  name="owner-manual"
                  type="checkbox"
                  value="Yes"
                />
                <label htmlFor="readTerms">Yes</label>
              </Flex>
            </div>
            <div className="fullWidth">
              <div>
                <strong>How was your overall experience with us?</strong>
              </div>
              <Flex
                sx={{
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  fontSize: '1.4rem',
                  position: 'relative',
                  pt: '25px'
                }}
              >
                <p
                  sx={{
                    position: ['absolute', 'static', null],
                    top: '-5px',
                    left: 0
                  }}
                >
                  Deceptive
                </p>
                {Array.from(Array(4), (_n, i) => {
                  return (
                    <Flex
                      sx={{ alignItems: 'center', input: { mt: '-2px' } }}
                      key={`radioExp${i}`}
                    >
                      <input
                        id={`radioExp${i + 1}`}
                        name="experience"
                        type="radio"
                        value={i + 1}
                      />
                      <label htmlFor={`radioExp${i + 1}`}>{i + 1}</label>
                    </Flex>
                  )
                })}
                <p
                  sx={{
                    position: ['absolute', 'static', null],
                    top: '-5px',
                    right: 0
                  }}
                >
                  Awesome!
                </p>
              </Flex>
            </div>
            <p className="fullWidth">
              <label htmlFor="commentsBike">
                If you weren't super happy, how can we improve your experience?
              </label>
              <textarea
                cols="30"
                id="commentsBike"
                name="comments"
                placeholder="Share your experience"
                rows="10"
              ></textarea>
            </p>
            <input
              className="fullWidth"
              type="submit"
              value="Send"
              id="submit-form"
            />
          </div>
        </fieldset>
        <div className="progress-update">
          <progress
            max="100"
            value="0"
            className="html5"
            id="progress-bar-upload"
          >
            <div className="progress-bar">
              <span
                sx={{ width: '0%', fontSize: '14px' }}
                data-open-accessibility-text-original="14px"
              >
                0%
              </span>
            </div>
          </progress>
        </div>
      </form>
    </Container>
  )
}
export default ProductRegistrationPage
