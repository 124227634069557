import { graphql } from 'gatsby'
import React, { useEffect } from 'react'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'
import ProductRegistrationPage from '~/components/Product/Registration'
import { navigate } from 'gatsby'

const RegisterProduct = ({ data }) => {
  const { page } = data
  useEffect(() => {
    if (!page) {
      navigate('/')
    }
  }, [])
  return (
    <Layout>
      {page && (
        <>
          <Metadata
            title={page.metaTitle || page.title}
            description={
              page.metaDescription?.metaDescription ||
              `Super73's ${page.title} page default description`
            }
          />
          <ProductRegistrationPage page={page} />
        </>
      )}
    </Layout>
  )
}

export default RegisterProduct

export const query = graphql`
  query RegisterProductPage($locale: String) {
    page: contentfulPage(
      slug: { eq: "register-product" }
      node_locale: { eq: $locale }
    ) {
      title
      metaTitle
      metaDescription {
        metaDescription
      }
      hero {
        ...ContentHeroFragment
      }
    }
  }
`
